<template>
  <v-layout max-width class="flex-column" style="min-height:100%;">
    <div class="flex-0 flex-shrink-0 flex-y-center flex-justify-between px-2 white" style>
      <!-- active=[trees[0].id] -->
      <!-- getStageAndEvent(), -->
      <v-tabs v-model="tab" @change="chooseId='',initStageList(),getProgressCategory(),getProjectCatalogueList()">
        <v-tab :href="`#0`">项目进度</v-tab>
        <v-tab :href="`#1`">中心进度</v-tab>
      </v-tabs>
    </div>
    <!-- <div class=" flex-1"> -->

    <div class="flex-1 flex-align-start" style="margin-top:14px;">
      <v-row dense>
        <v-col :md="chooseId?5:12">
          <v-card class="elevation-0">
            <div class="flex-y-center pt-4 px-3 flex-wrap">
              <v-btn
                color="#00bea9"
                depressed
                dark
                small
                height="30"
                style="padding:0 8px;"
                min-width="30"
                class="ml-2 mb-3"
                @click="dialog.data={},dialog.show=true"
              >
                <v-icon size="16">mdi-plus</v-icon>新增{{ tab=='0'?'项目':'中心' }}阶段
              </v-btn>
              <v-btn
                color="primary"
                depressed
                dark
                small
                height="30"
                style=" padding:0 8px;"
                min-width="100"
                class="ml-2 mb-3"
                @click="showTongbu"
              >
                前台进度同步
              </v-btn>
              <div class=" flex-y-center ml-2 mb-3">
                <span class=" flex-0 flex-shrink-0">是否允许前台创建阶段：{{ progressCategory==='0'?'否':'是' }}</span>

                <v-dialog
                  v-model="setDialog.show"
                  width="500"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <a class=" ml-2" v-bind="attrs" v-on="on" @click="setDialog.createFlag=progressCategory">修改</a>
                  </template>
                  <v-card>
                    <v-card-title class="font-weight-bold">
                      是否允许前台创建阶段
                    </v-card-title>
                    <v-divider />
                    <v-card-text class=" body-1">
                      <div class="flex-1 flex-column overflow-auto mt-7 px-12">
                        <div class="mb-6 flex-align-start">
                          <div class="flex-0 flex-shrink-0 flex-justify-end mt-2">
                            <span class="red--text ml-1">*</span>是否允许前台创建阶段：
                          </div>
                          <form-item
                            v-model="setDialog.createFlag"
                            class="mr-0"
                            :options="[{text:'是',value:'1'},{text:'否',value:'0'}]"
                            required
                            placeholder="阶段名称"
                            type="radio"
                          />
                        </div>
                      </div>
                    </v-card-text>
                    <v-divider />
                    <v-card-actions>
                      <v-spacer />
                      <v-btn width="80" height="30" class depressed @click="setDialog.show = false,setDialog.createFlag=''">取消</v-btn>
                      <v-btn
                        :disabled="!setDialog.createFlag"
                        :loading="btnLoading"
                        width="80"
                        type="submit"
                        class="ml-3"
                        height="30"
                        color="primary"
                        depressed
                        @click="setFlag"
                      >确定</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </div>

            </div>
            <v-data-table
              :headers="headers"
              :items="list"
              :items-per-page="search.pageSize"
              class="flex-1 pb-5 max-width header-grey px-4"
              hide-default-footer
              item-key="id"
              no-data-text="暂无查询数据"
              :loading="listLoading"
            >
              <!-- <template v-slot:item="{expand,item}"> -->
              <template v-slot:item.rowIndex="{index}">
                {{ (index+1)+(search.pageSize*(search.pageNo-1)) }}
              </template>
              <template v-slot:item.radio="{item}">
                <v-radio-group
                  v-model="chooseId"
                  dense
                  height="18px"
                  class="ma-0 pa-0"
                  column
                  @change="chooseIt"
                >
                  <v-radio :value="item.id" class="mt-5" />
                </v-radio-group>
              </template>
              <template v-slot:item.createFlag="{item}">
                {{ item.createFlag==1?'是':'否' }}
              </template>
              <template v-slot:item.statusFlag="{item}">
                <span :class="item.statusFlag==1?'error--text':''">{{ item.statusFlag==1?'禁用':'可用' }}</span>
              </template>
              <template v-slot:item.active="{item}">
                <div class=" flex-y-center">
                  <v-icon color="primary" size="18" class=" mr-1" title="编辑" @click="dialog.data={...item},dialog.show=true">mdi-square-edit-outline</v-icon>

                  <v-icon
                    v-if="item.statusFlag==1"
                    color="primary"
                    size="18"
                    class=" mr-1"
                    title="恢复"
                    @click="activeList($api.progressStageSetStageStatus,'post','恢复',{id:item.id,statusFlag:'0'})"
                  >mdi-replay</v-icon>
                  <v-icon
                    v-else
                    color="error"
                    size="18"
                    class=" mr-1"
                    title="禁用"
                    @click="activeList($api.progressStageSetStageStatus,'post','禁用',{id:item.id,statusFlag:'1'})"
                  >mdi-cancel</v-icon>
                  <v-icon
                    color="error"
                    size="20"
                    class=" mr-1"
                    title="删除"
                    @click="activeList($api.progressStageDelete,'delete','删除',{id:item.id})"
                  >mdi-delete-outline</v-icon>

                  <!-- <v-icon v-if="item.statusFlag==1" color="primary" size="18" class=" mr-1" title="恢复" @click="progressStageSetStageStatus(item.id,'0')">mdi-replay</v-icon>
                  <v-icon v-else color="error" size="18" class=" mr-1" title="禁用" @click="progressStageSetStageStatus(item.id,'1')">mdi-cancel</v-icon>
                  <v-icon color="error" size="20" class=" mr-1" title="删除"
                  @click="showDel(item.id)">mdi-delete-outline</v-icon> -->
                </div>

                <!-- <a class="mr-2" @click="dialog.data={...item},dialog.show=true">编辑</a>
                <a v-if="item.statusFlag==1" class="mr-2" @click="progressStageSetStageStatus(item.id,'0')">恢复</a>
                <a v-else class="mr-2 error--text" @click="progressStageSetStageStatus(item.id,'1')">禁用</a>
                <a class="mr-2 error--text" @click="showDel(item.id)">删除</a> -->
              </template>
            </v-data-table>
            <div class=" flex-justify-end">
              <pagination v-model="search.pageNo" :size="search.pageSize" :total="total" @input="getProgressStageList" @changePageSize="changePageSize" />
            </div>
          </v-card>
        </v-col>
        <v-col v-if="chooseId" md="7" class>
          <v-card class="elevation-0 ml-1">
            <div class="flex-y-center pt-4 px-3 mb-3">
              <v-btn
                color="#00bea9"
                depressed
                dark
                small
                height="30"
                style=" padding:0 8px;"
                min-width="30"
                class="ml-2"
                @click="eventDialog.data={tagIds:[],planStartTimeAuto:'0',planEndTimeAuto:'0'},eventDialog.show=true,getStageAndEvent()"
              >
                <v-icon size="18">mdi-plus</v-icon>新增事件
              </v-btn>
            </div>
            <div class="flex-y-center">
              <v-data-table
                :headers="event.headers"
                :items="event.list"
                :items-per-page="event.search.pageSize"
                class="flex-1 pb-5 max-width header-grey px-4"
                hide-default-footer
                item-key="id"
                no-data-text="暂无查询数据"
                :loading="event.listLoading"
              >

                <template v-slot:item.rowIndex="{index}">
                  {{ (index+1) }}
                </template>
                <template v-slot:item.createFlag="{item}">
                  {{ item.createFlag==1?'是':'否' }}
                </template>
                <template v-slot:item.statusFlag="{item}">
                  <span :class="item.statusFlag==1?'error--text':''">{{ item.statusFlag==1?'禁用':'可用' }}</span>
                </template>
                <!-- <template v-slot:item="{expand,item}"> -->
                <template v-slot:item.active="{item}">
                  <div class=" flex-y-center">
                    <v-icon color="primary" size="18" class=" mr-1" title="编辑" @click="showEdit(item)">mdi-square-edit-outline</v-icon>
                    <!-- <v-icon v-if="item.statusFlag==1" color="primary" size="18" class=" mr-1" title="恢复" @click="progressEventSetStageStatus(item.id,'0')">mdi-replay</v-icon>
                  <v-icon v-else color="error" size="18" class=" mr-1" title="禁用" @click="progressEventSetStageStatus(item.id,'1')">mdi-cancel</v-icon>
                  <v-icon color="error" size="20" class=" mr-1" title="删除"
                  @click="showEventDel(item.id)">mdi-delete-outline</v-icon> -->

                    <v-icon
                      v-if="item.statusFlag==1"
                      color="primary"
                      size="18"
                      class=" mr-1"
                      title="恢复"
                      @click="activeList($api.progressEventSetStageStatus,'post','恢复',{id:item.id,statusFlag:'0'},'event')"
                    >mdi-replay</v-icon>
                    <v-icon
                      v-else
                      color="error"
                      size="18"
                      class=" mr-1"
                      title="禁用"
                      @click="activeList($api.progressEventSetStageStatus,'post','禁用',{id:item.id,statusFlag:'1'},'event')"
                    >mdi-cancel</v-icon>
                    <v-icon
                      color="error"
                      size="20"
                      class=" mr-1"
                      title="删除"
                      @click="activeList($api.progressEventDelete,'delete','删除',{id:item.id},'event')"
                    >mdi-delete-outline</v-icon>
                    <!-- <a class="mr-2" @click="showEdit(item)">编辑</a> -->
                    <!-- <a v-if="item.statusFlag==1" class="mr-2" @click="progressEventSetStageStatus(item.id,'0')">恢复</a>
                  <a v-else class="mr-2 error--text" @click="progressEventSetStageStatus(item.id,'1')">禁用</a> -->
                    <!-- <a class="mr-2 error--text" @click="showEventDel(item.id)">删除</a> -->
                    <!-- <a class="mr-2" @click="showDrawer(item)">更多</a> -->
                    <v-menu
                      open-on-hover
                      bottom
                      offset-y
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <a class="mr-2" v-bind="attrs" v-on="on">更多</a>
                      </template>
                      <v-list dense>
                        <v-list-item @click="drawer.type=1,drawer.id=item.id,drawer.title='任务列表',progressEventTaskList(),showDrawer(item)">
                          <!-- this.progressEventTaskList()
                            this.progressEventTaskEventRemindList()
                            this.eventTagList()
                            this.eventPreconditionList() -->
                          <v-list-item-title>任务列表</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="drawer.type=2,drawer.id=item.id,drawer.title='提醒列表',progressEventTaskEventRemindList(),showDrawer(item)">
                          <v-list-item-title>提醒列表</v-list-item-title>
                        </v-list-item>
                        <!-- <v-list-item @click="drawer.type=3,drawer.id=item.id,drawer.title='节点列表',eventTagList(),showDrawer(item)" >
                          <v-list-item-title>节点列表</v-list-item-title>
                        </v-list-item> -->
                        <v-list-item @click="drawer.type=4,drawer.id=item.id,drawer.title='前置条件列表',getStageAndEvent(),eventPreconditionList(),showDrawer(item)">
                          <v-list-item-title>前置条件列表</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="drawer.type=5,drawer.id=item.id,drawer.title='必传附件',getEventUploadList(),showDrawer(item)">
                          <v-list-item-title>必传附件</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="drawer.type=6,drawer.id=item.id,drawer.title='文档提醒',showDrawer(item),getEventFileRemindList()">
                          <v-list-item-title>文档提醒</v-list-item-title>
                        </v-list-item>

                      </v-list>
                    </v-menu>
                  </div>

                </template>
              </v-data-table>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </div>

    <!-- 添加 编辑 阶段  start-->
    <v-dialog v-model="dialog.show" width="600">
      <form @submit.prevent="submitAdd()">
        <v-card>
          <v-card-title class="font-weight-bold">{{ dialog.data.id?'编辑阶段':'添加阶段' }}</v-card-title>
          <v-divider />
          <v-card-text class="py-5">
            <div class="flex-1 flex-column overflow-auto mt-7 px-12">
              <div class="mb-6 flex-align-start">
                <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2">
                  <span class="red--text ml-1">*</span>阶段名称：
                </div>
                <form-item
                  v-model="dialog.data.stageName"
                  class="mr-0"
                  required
                  placeholder="阶段名称"
                  type="text"
                />

              </div>
              <div class="mb-6 flex-align-start">
                <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2">
                  <span class="red--text ml-1">*</span>阶段类型：
                </div>
                <form-item
                  v-model="dialog.data.stageCategory"
                  class=" flex-1 flex-shrink-0"
                  :options="[{text:'项目',value:'0'},{text:'中心',value:'1'}]"
                  placeholder="选择阶段类型"
                  dense
                  required
                  type="select"
                />

              </div>
              <div class="mb-6 flex-align-start">
                <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2">
                  排序：
                </div>
                <form-item
                  v-model="dialog.data.sort"
                  class="mr-0"
                  placeholder="排序"
                  step="1"
                  :min="1"
                  type="number"
                />

              </div>
              <div class="mb-6 flex-align-start">
                <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2">
                  <span class="red--text ml-1">*</span>是否允许前台创建事件：
                </div>
                <form-item
                  v-model="dialog.data.createFlag"
                  :options="[{text:'是',value:'1'},{text:'否',value:'0'}]"
                  class="mb-4"
                  direction="left min-width"
                  type="radio"
                  required
                />
              </div>
            </div>
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <div class="max-width flex-y-center flex-justify-between">
              <div class="flex-1 flex-justify-end">

                <v-btn width="80" height="30" class depressed @click="dialog.show = false">取消</v-btn>
                <v-btn width="80" height="30" type="submit" :loading="btnLoading" class="ml-3" color="primary" depressed>确定</v-btn>
              </div>
            </div>
          </v-card-actions>
        </v-card>
      </form>
    </v-dialog>
    <!-- 添加 编辑 阶段  end-->

    <!-- 添加 编辑 事件  start-->
    <v-dialog v-model="eventDialog.show" width="700">
      <form v-if="eventDialog.show" class="max-height flex-column overflow-hidden" @submit.prevent="eventSubmitAdd">
        <v-card>
          <v-card-title class="font-weight-bold">{{ eventDialog.data.id?'编辑事件':'添加事件' }}</v-card-title>
          <v-divider />
          <v-card-text class="py-5">
            <div class="flex-1 flex-column overflow-auto mt-7 pr-12">
              <div class="mb-6 flex-align-start">
                <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2" style="width:170px;">
                  <span class="red--text ml-1">*</span>事件名称：
                </div>
                <form-item
                  v-model="eventDialog.data.eventName"
                  class="mr-0"
                  placeholder="事件名称"
                  required
                  type="text"
                />
              </div>
              <div class="mb-6 flex-align-start">
                <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2" style="width:170px;">
                  排序：
                </div>
                <form-item
                  v-model="eventDialog.data.sort"
                  class="mr-0"
                  placeholder="排序"
                  step="1"
                  :min="1"
                  type="number"
                />
              </div>
              <div class="mb-6 flex-align-start">
                <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2" style="width:170px;">
                  <span class="red--text ml-1">*</span>是否允许前台创建子任务：
                </div>
                <form-item
                  v-model="eventDialog.data.createFlag"
                  :options="[
                    {
                      text:'是',
                      value:'1'
                    },
                    {
                      text:'否',
                      value:'0'
                    }]"
                  class="flex-1 flex-shrink-0"
                  style="width:130px;"
                  direction=" left"
                  required
                  type="radio"
                />

              </div>
              <div class="mb-6 flex-align-start">
                <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2" style="width:170px;">
                  节点标签：
                </div>
                <form-item
                  v-model="eventDialog.data.tagIds"
                  class=" flex-1 flex-shrink-0"
                  :options="progressEventTagList"
                  placeholder="选择节点标签"
                  dense
                  multiple
                  type="select"
                />
              </div>
              <div class="mb-6 flex-align-start">
                <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2" style="width:170px;">
                  <span class="red--text ml-1">*</span>里程碑：
                </div>
                <form-item
                  v-model="eventDialog.data.milestone"
                  class=" flex-1"
                  :options="[{text:'是',value:'1'},{text:'否',value:'0'}]"
                  placeholder="里程碑"
                  style="width: 200px"
                  dense
                  type="radio"
                  required
                />

              </div>
              <!-- 以下 -->
              <div class="flex-align-start">
                <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2" style="width:170px;">
                  计划开始日期：
                </div>
                <form-item
                  v-model="eventDialog.data.planStartTimeAuto"
                  class=" flex-1"
                  :options="[{text:'是',value:'1'},{text:'否',value:'0'}]"
                  style="width: 200px"
                  dense
                  type="radio"
                  @input="inputPlanStartTimeAuto"
                />
              </div>
              <div v-if="eventDialog.data.planStartTimeAuto === '1'" class="mt-2 flex-align-start">
                <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2" style="width:170px;" />
                <v-menu
                  :close-on-content-click="false"
                  max-height="400"
                  nudge-bottom="5"
                  offset-y
                  offset-overflow
                  allow-overflow
                >
                  <template v-slot:activator="{ on }">
                    <div class=" flex-1 input default-input flex-justify-between flex-y-center mr-2 body-1" style="height:32px;position: relative;" v-on="on">
                      <input
                        class=" flex-1 flex-y-center"
                        style="width:1px;"
                        :title="startAutoEventName"
                        placeholder="选择事件名称"
                        :value="startAutoEventName"
                        readonly
                        type="text"
                      >
                      <v-icon size="20">mdi-chevron-down</v-icon>
                      <input :value="startAutoEventName" style="position: absolute;opacity: 0; width:1px;" class=" flex-1" required>
                    </div>
                  </template>
                  <v-card class=" elevation-0">
                    <v-treeview :items="stageAndEvent" item-children="events" dense open-all>
                      <template v-slot:label="{item}">
                        <template v-if="item.stageName">
                          {{ item.stageName }}
                        </template>
                        <template v-else>
                          <v-checkbox
                            v-model="eventDialog.data.startAutoEventId"
                            :ripple="false"
                            :value="item.id"
                            class="max-width mt-0 pt-0"
                            style="height:30px;"
                            color="primary"
                            dense
                            :disabled="eventDialog.data.id && (item.id == eventDialog.data.id)"
                            @change="(e)=>{changepreEvent(e,item.id,'startAutoEventId')}"
                          >
                            <template v-slot:label>
                              <v-list-item-content class="ml-3">
                                <v-list-item-title class="black--text">{{ item.eventName || '-' }}</v-list-item-title>
                              </v-list-item-content>
                            </template>
                          </v-checkbox>
                        </template>

                      </template>
                    </v-treeview>
                  </v-card>
                </v-menu>
                <form-item
                  v-model="eventDialog.data.startAutoType"
                  class=" flex-1 mr-2"
                  :options="[{text:'计划开始日期',value:'0'},{text:'计划结束日期',value:'1'}]"
                  placeholder=""
                  style="width: 200px"
                  dense
                  required
                  type="select"
                />
                <form-item
                  v-model="eventDialog.data.startAutoDayType"
                  class=" flex-1 mr-2"
                  :options="[{text:'工作日',value:'0'},{text:'自然日',value:'1'}]"
                  placeholder=""
                  style="width: 200px"
                  dense
                  required
                  type="select"
                />
                <form-item
                  v-model="eventDialog.data.startAutoDays"
                  class=" flex-1"
                  placeholder="天数"
                  style="width: 60px"
                  dense
                  type="text"
                  pattern="zhengshu"
                  required
                />
              </div>
              <div class="mt-6 flex-align-start">
                <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2" style="width:170px;">
                  计划结束日期：
                </div>
                <form-item
                  v-model="eventDialog.data.planEndTimeAuto"
                  class=" flex-1"
                  :options="[{text:'是',value:'1'},{text:'否',value:'0'}]"
                  placeholder="计划结束日期"
                  style="width: 200px"
                  dense
                  type="radio"
                  @input="inputPlanEndTimeAuto"
                />
              </div>
              <div v-if="eventDialog.data.planEndTimeAuto==='1'" class="mt-2 flex-align-start">
                <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2" style="width:170px;" />
                <v-menu
                  :close-on-content-click="false"
                  max-height="400"
                  nudge-bottom="5"
                  offset-y
                  offset-overflow
                  allow-overflow
                >
                  <template v-slot:activator="{ on }">
                    <div class=" flex-1 input default-input flex-justify-between flex-y-center mr-2 body-1" style="height:32px;position:relative" v-on="on">
                      <input
                        class=" flex-1 flex-y-center"
                        style="width:1px;"
                        :title="endAutoEventName"
                        placeholder="选择事件名称"
                        :value="endAutoEventName"
                        readonly
                        type="text"
                      >
                      <v-icon size="20">mdi-chevron-down</v-icon>
                      <input :value="endAutoEventName" style="position: absolute;opacity: 0; width:1px;" class=" flex-1" required>
                    </div>
                  </template>
                  <v-card class=" elevation-0">
                    <v-treeview :items="stageAndEvent" item-children="events" dense open-all>
                      <template v-slot:label="{item}">
                        <template v-if="item.stageName">
                          {{ item.stageName }}
                        </template>
                        <template v-else>
                          <v-checkbox
                            v-model="eventDialog.data.endAutoEventId"
                            :ripple="false"
                            :value="item.id"
                            class="max-width mt-0 pt-0"
                            style="height:30px;"
                            color="primary"
                            dense
                            :disabled="eventDialog.data.id && (item.id == eventDialog.data.id)"
                            @change="(e)=>{changepreEvent(e,item.id,'endAutoEventId')}"
                          >
                            <template v-slot:label>
                              <v-list-item-content class="ml-3">
                                <v-list-item-title class="black--text">{{ item.eventName || '-' }}</v-list-item-title>
                              </v-list-item-content>
                            </template>
                          </v-checkbox>
                        </template>

                      </template>
                    </v-treeview>
                  </v-card>
                </v-menu>
                <form-item
                  v-model="eventDialog.data.endAutoType"
                  class=" flex-1 mr-2"
                  :options="[{text:'计划开始日期',value:'0'},{text:'计划结束日期',value:'1'}]"
                  placeholder=""
                  style="width: 200px"
                  dense
                  required
                  type="select"
                />
                <form-item
                  v-model="eventDialog.data.endAutoDayType"
                  class=" flex-1 mr-2"
                  :options="[{text:'工作日',value:'0'},{text:'自然日',value:'1'}]"
                  placeholder=""
                  style="width: 200px"
                  dense
                  type="select"
                  required
                />
                <form-item
                  v-model="eventDialog.data.endAutoDays"
                  class=" flex-1"
                  placeholder="天数"
                  style="width: 60px"
                  dense
                  type="text"
                  pattern="zhengshu"
                  required
                />
              </div>
            </div>
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-spacer />
            <v-btn width="80" height="30" class="mr-2" depressed @click="eventDialog.show = false">取消</v-btn>
            <!-- <v-btn width="80" height="30" :loading="btnLoading" class="ml-3" color="primary" depressed type="submit">确定</v-btn> -->
            <div style=" position:relative;">
              <input ref="form" style=" position:absolute;" type="submit" value="submit">
              <v-btn height="30" :loading="btnLoading" color="primary" width="80" depressed @click.stop="subSave">提交</v-btn>
            </div>
          </v-card-actions>
        </v-card>
      </form>
    </v-dialog>
    <!-- 添加 编辑 事件  end-->

    <!-- 添加 编辑 事件里的弹窗  start-->
    <v-dialog v-model="otherDialog.show" persistent width="600">
      <form v-if="otherDialog.show" @submit.prevent="otherSubmitAdd">
        <v-card>
          <v-card-title class=" font-weight-bold">{{ otherDialog.data.id?'编辑':'添加' }}</v-card-title>
          <v-divider />
          <v-card-text v-if="otherDialog.show" class="py-5 body-1">
            <div class="flex-1 flex-column overflow-auto mt-7 px-12">
              <!-- 任务 -->
              <template v-if="drawer.type==1">
                <div class="mb-6 flex-align-start">
                  <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2" style="width:90px;">
                    <span class="red--text ml-1">*</span>任务名称：
                  </div>
                  <form-item
                    v-model="otherDialog.data.taskName"
                    class="mr-0"
                    placeholder="任务名称"
                    type="text"
                  />
                </div>
                <div class="mb-6 flex-align-start">
                  <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2" style="width:90px;">
                    路径：
                  </div>
                  <v-menu
                    :close-on-content-click="false"
                    max-height="400"
                    nudge-bottom="5"
                    offset-y
                  >
                    <template v-slot:activator="{ on }">
                      <div class="input default-input flex-justify-between flex-y-center" style="height:36px;position:relative" v-on="on" @click="folderActive=(otherDialog.data.folderId?[otherDialog.data.folderId]:[]),$forceUpdate()">
                        <input
                          class=" flex-1 flex-y-center"
                          placeholder="选择路径"
                          :value="otherDialog.data.folderPath"
                          readonly
                          type="text"
                        >
                        <v-icon size="20">mdi-chevron-down</v-icon>
                        <input :value="otherDialog.data.folderPath" style="position: absolute;opacity: 0; width:1px;" class=" flex-1">
                      </div>
                    </template>
                    <v-card class=" elevation-0">
                      <v-treeview
                        class
                        item-children="children"
                        :items="trees"
                        transition
                      >
                        <template v-slot:label="{ item }">
                          <v-list-item-content class="ml-3">
                            <div class=" flex-y-center">
                              <input v-if="item.statusFlag!='1'" v-model="taskPathId" type="checkbox" class=" mr-2" :value="item.id" @change="updateTaskActive">
                              <v-list-item-title class="black--text">{{ item.folderName || '-' }}</v-list-item-title>
                            </div>
                          </v-list-item-content>
                          <!-- <v-checkbox
                            v-model="otherDialog.data.pathId"
                            :value="item.id"
                            class="max-width mt-0 pt-0"
                            style="height:30px;"
                            color="primary"
                            dense
                            @change="(e)=>{updateTaskActive(e),$forceUpdate()}"
                          >
                            <template v-slot:label>
                              <v-list-item-content class="ml-3">
                                <v-list-item-title class="black--text">{{ item.id + item.folderName || '-' }}</v-list-item-title>
                              </v-list-item-content>
                            </template>
                          </v-checkbox> -->

                        </template>
                      </v-treeview>
                    </v-card>
                  </v-menu>
                </div>
              </template>
              <!-- 提醒 -->
              <template v-else-if="drawer.type==2">
                <div class="mb-6 flex-align-start">
                  <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2" style="width:90px;">
                    <span class="red--text ml-1">*</span>状态：
                  </div>
                  <form-item
                    v-model="otherDialog.data.remindStatus"
                    class=" flex-1 flex-shrink-0"
                    :options="[{text:'未开始',value:'0'},{text:'进行中',value:'1'},{text:'已完成',value:'2'}]"
                    placeholder="选择状态"
                    dense
                    type="select"
                  />
                </div>
                <div class="mb-6 flex-align-start">
                  <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2" style="width:90px;">
                    <span class="red--text ml-1">*</span>语句：
                  </div>
                  <form-item
                    v-model="otherDialog.data.remindTip"
                    class="mr-0"
                    placeholder="语句"
                    type="textarea"
                  />
                </div>
                <div class="mb-6 flex-align-start">
                  <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2" style="width:90px;">
                    <span class="red--text ml-1">*</span>提醒角色：
                  </div>
                  <form-item
                    v-model="otherDialog.remindRole"
                    class=" flex-1 flex-shrink-0"
                    :options="roleList"
                    placeholder="选择角色"
                    dense
                    multiple
                    type="select"
                  />
                </div>
              </template>
              <!-- 节点 -->
              <template v-else-if="drawer.type==3">
                <div class="mb-6 flex-align-start">
                  <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2" style="width:90px;">
                    <span class="red--text ml-1">*</span>节点标签：
                  </div>
                  <form-item
                    v-model="otherDialog.data.tagIds"
                    class=" flex-1 flex-shrink-0"
                    :options="progressEventTagList"
                    placeholder="选择节点标签"
                    dense
                    multiple
                    type="select"
                  />
                </div>
              </template>
              <!-- 前置条件 -->
              <template v-else-if="drawer.type==4">
                <div class="mb-6 flex-align-start">
                  <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2" style="width:90px;">
                    <span class="red--text ml-1">*</span>事件名称：
                  </div>
                  <!-- :value="selectName" -->
                  <v-menu
                    :close-on-content-click="false"
                    max-height="400"
                    nudge-bottom="5"
                    offset-y
                  >
                    <template v-slot:activator="{ on }">
                      <div class="input default-input flex-justify-between flex-y-center" style="height:36px;" v-on="on">
                        <input
                          class=" flex-1 flex-y-center"
                          placeholder="选择事件名称"
                          :value="selectName"
                          readonly
                          type="text"
                        >
                        <v-icon size="20">mdi-chevron-down</v-icon>
                      </div>
                    </template>
                    <v-card class=" elevation-0">
                      <v-treeview :items="stageAndEvent" item-children="events">
                        <template v-slot:label="{item}">
                          <template v-if="item.stageName">
                            {{ item.stageName }}
                          </template>
                          <template v-else>
                            <v-checkbox
                              v-model="otherDialog.data.preEventId"
                              :ripple="false"
                              :value="item.id"
                              class="max-width mt-0 pt-0"
                              style="height:30px;"
                              color="primary"
                              @change="(e)=>{changepreEvent(e,item.id)}"
                            >
                              <!-- value
                                v-model="otherDialog.data.preEventId" -->
                              <template v-slot:label>
                                <v-list-item-content class="ml-3">
                                  <v-list-item-title class="black--text">{{ item.eventName || '-' }}</v-list-item-title>
                                </v-list-item-content>
                              </template>
                            </v-checkbox>
                          </template>

                        </template>
                      </v-treeview>
                    </v-card>
                  </v-menu>

                  <!-- <form-item
                        class=" flex-1 flex-shrink-0"
                        :options="stageAndEvent"
                        placeholder="选择阶段名称"
                        dense
                        multiple
                        type="select"
                        v-model="otherDialog.data.tagIds"
                      ></form-item> -->
                </div>
                <div class="mb-6 flex-align-start">
                  <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2" style="width:90px;">
                    <span class="red--text ml-1">*</span>事件状态：
                  </div>
                  <!-- otherDialog.data.taskStatus -->
                  <form-item
                    v-model="otherDialog.taskStatus"
                    class=" flex-1 flex-shrink-0"
                    :options="Object.values(taskStatus)"
                    placeholder="事件状态"
                    dense
                    multiple
                    type="select"
                  />
                </div>
                <div class="mb-6 flex-align-start">
                  <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2" style="width:90px;">
                    <span class="red--text ml-1">*</span>排序：
                  </div>
                  <!-- otherDialog.data.taskStatus -->
                  <form-item
                    v-model="otherDialog.data.sort"
                    class=" flex-1 flex-shrink-0"
                    placeholder="排序"
                    dense
                    type="text"
                  />
                </div>

              </template>
              <!-- 必传附件 -->
              <template v-else-if="drawer.type==5">
                <div class="mb-6 flex-align-start">
                  <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2" style="width:170px;">
                    <span class="red--text ml-1">*</span>文件夹目录名称：
                  </div>
                  <form-item
                    v-model="eventDialog.data.catalogueId"
                    class=" flex-1 flex-shrink-0"
                    :options="ProjectCatalogueList"
                    placeholder="选择文件夹目录名称"
                    dense
                    required
                    type="select"
                    @select="handlerSelect"
                  />
                </div>
                <div class="mb-6 flex-align-start">
                  <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2" style="width:170px;">
                    <span class="red--text ml-1">*</span>路径：
                  </div>
                  <v-menu
                    :close-on-content-click="false"
                    max-height="400"
                    nudge-bottom="5"
                    offset-y
                  >
                    <template v-slot:activator="{ on }">
                      <div class="input default-input flex-justify-between flex-y-center" style="height:36px;position:relative" v-on="on" @click="folderActive=(otherDialog.data.folderId?[otherDialog.data.folderId]:[]),$forceUpdate()">
                        <input
                          class=" flex-1 flex-y-center"
                          placeholder="选择事件名称"
                          :value="otherDialog.data.folderPath"
                          readonly
                          type="text"
                        >
                        <v-icon size="20">mdi-chevron-down</v-icon>
                        <input :value="otherDialog.data.folderPath" style="position: absolute;opacity: 0; width:1px;" class=" flex-1" required>
                      </div>
                    </template>
                    <v-card class=" elevation-0">
                      <!-- <v-treeview activatable dense :items="trees" item-text="folderName" item-children="children" :active="folderActive" @update:active='chooseFolderTree'>
                          <template v-slot:label="{item}">
                            <span class=" cursor_pointer" @click="otherDialog.data.folderPath=item.folderName,otherDialog.data.folderId=item.id">
                              {{item.folderName}}
                            </span>
                          </template>
                        </v-treeview> -->
                      <v-treeview
                        class
                        item-children="children"
                        :items="trees"
                        transition
                      >
                        <template v-slot:label="{ item }">
                          <div
                            class="flex-y-center"
                            style="min-height: 38px"
                            @click="item.statusFlag!='1'&&updateActive(item)"
                          >
                            <div
                              class="max-width max-height flex-y-center user-select"
                              style="min-height: 38px"
                              :title="item.folderName"
                            >
                              <!-- information.data.statusFlag=='1' -->
                              <a
                                class="grey--text text--darken-3 pl-1 max-width max-height flex-y-center"
                                :class="(folderActive.length&&(folderActive[0]===item.id))?'light-blue lighten-5':''"
                                style="min-height: 38px"
                                :style="{color:(item.statusFlag=='1')?'#ccc !important':'',cursor:(item.statusFlag=='1')?'text':'pointer'}"
                              >{{ item.folderName }}

                              </a>
                            </div>
                          </div>
                        </template>
                      </v-treeview>
                    </v-card>
                  </v-menu>
                </div>
                <div class="mb-6 flex-align-start">
                  <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2" style="width:170px;">
                    <span class="red--text ml-1">*</span>归档通过文件个数：
                  </div>
                  <!-- <form-item
                    v-model="otherDialog.data.fileNumber"
                    class="mr-0"
                    required
                    placeholder="归档通过文件个数"
                    type="text"
                  /> -->
                  <form-item
                    v-model="otherDialog.data.fileNumber"
                    class="mr-0"
                    placeholder="文件最小个数"
                    :max="otherDialog.data.fileNumberMax"
                    type="text"
                    pattern="justInteger"
                  />
                  <span class="px-1">~</span>
                  <form-item
                    v-model="otherDialog.data.fileNumberMax"
                    class="mr-0"
                    placeholder="文件最大个数"
                    :min="otherDialog.data.fileNumber"
                    type="text"
                    pattern="justInteger"
                  />
                </div>
                <div class="mb-6 flex-align-start">
                  <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2" style="width:170px;">
                    <span class="red--text ml-1">*</span>归档后事件自动完成：
                  </div>
                  <form-item
                    v-model="otherDialog.data.autoDone"
                    class="mr-0"
                    placeholder="归档后事件自动完成"
                    required
                    :options="[{text:'是',value:'1'},{text:'否',value:'0'}]"
                    type="radio"
                  />
                </div>
              </template>
            </div>
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <div class="max-width flex-y-center flex-justify-between">
              <div class="flex-1 flex-justify-end">
                <v-btn width="80" height="30" class="mr-3" depressed @click="taskPathId = [],otherDialog.show = false">取消</v-btn>
                <!-- <v-btn width="80" height="30" :loading="btnLoading" color="primary" depressed type="submit">确定</v-btn> -->
                <div style=" position:relative;">
                  <input ref="otherForm" style=" position:absolute;" type="submit" value="submit">
                  <v-btn height="30" :loading="btnLoading" color="primary" width="80" depressed @click.stop="subOtherSave">提交</v-btn>
                </div>

              </div>
            </div>
          </v-card-actions>
        </v-card>
      </form>
    </v-dialog>
    <!-- 添加 编辑 事件里的弹窗  end-->

    <!-- 事件列表 更多操作 弹窗 呵呵 start -->
    <v-dialog v-model="drawer.show" width="800" :content-class="drawer.show?'flex-column overflow-hidden':''">
      <v-card class=" flex-1 flex-column overflow-hidden">
        <!-- <v-card style="height:680px;" class=" flex-column"> -->
        <v-card-title class=" flex-0 font-weight-bold">{{ document.isRemind? '编辑文档提醒' : drawer.title }}</v-card-title>
        <v-divider />
        <v-card-text v-if="drawer.show" class=" flex-1 flex-column pb-5 pt-3 px-0 overflow-hidden" :style="drawer.type==6? 'min-height:200px' : 'min-height:400px'">
          <!-- <div class="flex-1 flex-column overflow-auto"> -->
          <template v-if="drawer.type==1">
            <div class=" flex-justify-end mr-3 mb-2">
              <v-btn width="80" height="30" color="primary" class="" depressed @click="isEditTaskPath = false,taskPathId=[],otherDialog.data={folderPath:''},otherDialog.show=true">添加任务</v-btn>
            </div>
            <!-- <a @click="otherDialog.data={},otherDialog.show=true">添加任务</a> -->
            <div class="flex-1 flex-column overflow-auto">
              <v-data-table
                :headers="drawer.task.headers"
                :items="drawer.task.list"
                :items-per-page="999"
                class="pb-5 max-width header-grey"
                hide-default-footer
                item-key="id"
                no-data-text="暂无查询数据"
              >
                <template v-slot:item.rowIndex="{index}">
                  {{ (index+1) }}
                </template>
                <template v-slot:item.uploadPath="{item}">
                  <div style="max-width:400px;" class=" font-overflow" :title="item.uploadPath">{{ item.uploadPath }}</div>
                </template>
                <template v-slot:item.active="{item}">
                  <a class="mr-3" @click="editTask(item)">编辑</a>
                  <a @click="otherDel(item.id,$api.progressEventTaskDelete,progressEventTaskList)">删除</a>
                </template>
              </v-data-table>
            </div>
          </template>
          <template v-else-if="drawer.type==2">
            <div class=" flex-justify-end mr-3 mb-2">
              <v-btn width="80" height="30" color="primary" class="" depressed @click="otherDialog.data={},otherDialog.remindRole=[],otherDialog.show=true">添加提醒</v-btn>
            </div>
            <!-- <a @click="otherDialog.data={},otherDialog.show=true">添加提醒</a> -->
            <div class="flex-1 flex-column overflow-auto">
              <v-data-table
                :headers="drawer.remind.headers"
                :items="drawer.remind.list"
                :items-per-page="999"
                class="pb-5 max-width header-grey"
                hide-default-footer
                item-key="id"
                no-data-text="暂无查询数据"
              >

                <template v-slot:item.rowIndex="{index}">
                  {{ (index+1) }}
                </template>
                <template v-slot:item.remindTip="{item}">
                  <div class=" font-overflow" style="max-width:380px;" :title="item.remindTip">{{ item.remindTip }}</div>
                </template>
                <template v-slot:item.remindStatus="{item}">
                  <!-- 提醒状态 0:未开始 1:进行中 2:已完成 -->
                  {{ item.remindStatus==1?'进行中':(item.remindStatus==2?'已完成':(item.remindStatus=='0'?'未开始':'-')) }}
                </template>
                <template v-slot:item.active="{item}">
                  <a class="mr-2" @click="otherDialog.data={...item},(otherDialog.remindRole=otherDialog.data.remindRole?otherDialog.data.remindRole.split(','):[]),otherDialog.show=true">编辑</a>
                  <a @click="otherDel(item.id,$api.progressEventTaskEventRemindDelete,progressEventTaskEventRemindList)">删除</a>
                </template>
              </v-data-table>
            </div>
          </template>
          <template v-else-if="drawer.type==3">
            <div class=" flex-justify-end mr-3 mb-2">
              <v-btn width="80" height="30" color="primary" class="" depressed @click="otherDialog.data={tagIds:[]},otherDialog.show=true">添加节点</v-btn>
            </div>
            <!-- <a @click="otherDialog.data={tagIds:[]},otherDialog.show=true">添加节点</a> -->
            <div class="flex-1 flex-column overflow-auto">
              <v-data-table
                :headers="drawer.tag.headers"
                :items="drawer.tag.list"
                :items-per-page="999"
                class="pb-5 max-width"
                hide-default-footer
                item-key="id"
                no-data-text="暂无查询数据"
              >
                <template v-slot:item.rowIndex="{index}">
                  {{ (index+1) }}
                </template>
                <!-- <template v-slot:item.active>
                  <a  @click="drawer.show=true">编辑</a>
                </template> -->
              </v-data-table>
            </div>
          </template>
          <template v-else-if="drawer.type==4">
            <div class=" flex-justify-end mr-3 mb-2">
              <!-- preEventId:[] otherDialog.data.preEventId=[],-->
              <v-btn width="110" height="30" color="primary" class="" depressed @click="otherDialog.data={},otherDialog.taskStatus=[],otherDialog.show=true">添加前置条件</v-btn>
            </div>
            <!-- <a @click="otherDialog.data={},otherDialog.show=true">添加前置条件</a> -->
            <div class="flex-1 flex-column overflow-auto">
              <v-data-table
                :headers="drawer.front.headers"
                :items="drawer.front.list"
                :items-per-page="999"
                class="pb-5 max-width header-grey"
                hide-default-footer
                item-key="id"
                no-data-text="暂无查询数据"
              >
                <template v-slot:item.rowIndex="{index}">
                  {{ (index+1) }}
                </template>
                <template v-slot:item.taskStatus="{item}">
                  <template v-if="item.taskStatus">
                    {{ taskStatusBack(item.taskStatus) }}
                  </template>
                </template>
                <template v-slot:item.active="{item}">
                  <a class="mr-2" @click="frontEdit({...item})">编辑</a>
                  <a @click="otherDel(item.id,$api.deleteEventPrecondition,eventPreconditionList)">删除</a>
                </template>
              </v-data-table>
            </div>
          </template>
          <template v-else-if="drawer.type==5">
            <div class=" flex-justify-end mr-3 mb-2">
              <!-- preEventId:[] otherDialog.data.preEventId=[],-->
              <v-btn width="110" height="30" color="primary" class="" depressed @click="otherDialog.data={},otherDialog.taskStatus=[],otherDialog.show=true,eventDialog.data.catalogueId = ''">添加必传附件</v-btn>
            </div>
            <div class="flex-1 flex-column overflow-auto">
              <v-data-table
                :headers="drawer.upload.headers"
                :items="drawer.upload.list"
                :items-per-page="999"
                class="pb-5 max-width header-grey"
                hide-default-footer
                item-key="id"
                no-data-text="暂无查询数据"
              >
                <template v-slot:item.rowIndex="{index}">
                  {{ (index+1) }}
                </template>
                <template v-slot:item.autoDone="{item}">
                  {{ item.autoDone == '1'?'是':'否' }}
                </template>
                <template v-slot:item.active="{item}">
                  <a class="mr-2" @click="otherDialog.data={...item},otherDialog.show=true">编辑</a>
                  <a @click="otherDel(item.id,$api.deleteEventUpload,getEventUploadList)">删除</a>
                </template>
              </v-data-table>
            </div>
          </template>
          <template v-if="drawer.type==6">
            <div style="padding: 0 50px">
              <div class="mb-6 flex-align-start">
                <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2" style="width:170px;">
                  <span class="red--text ml-1">*</span>{{ documentReminderName }}：
                </div>
                <form-item
                  v-model="eventDialog.data.catalogueId"
                  class=" flex-1 flex-shrink-0"
                  :options="documentReminderList"
                  placeholder="选择文档目录"
                  dense
                  required
                  type="select"
                  @select="handlerSelect"
                />
              </div>
              <div class="mb-6 flex-align-start">
                <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2" style="width:170px;">
                  <span class="red--text ml-1">*</span>{{ documentReminderRulName }}：
                </div>
                <v-menu
                  :close-on-content-click="false"
                  max-height="400"
                  nudge-bottom="5"
                  offset-y
                >
                  <template v-slot:activator="{ on }">
                    <div class="input default-input flex-justify-between flex-y-center" style="height:36px;position:relative" v-on="on" @click="folderActive=(otherDialog.data.folderId?[otherDialog.data.folderId]:[]),$forceUpdate()">
                      <input
                        class=" flex-1 flex-y-center"
                        placeholder="选择文档路径"
                        :value="otherDialog.data.folderPath"
                        readonly
                        type="text"
                      >
                      <v-icon size="20">mdi-chevron-down</v-icon>
                      <input :value="otherDialog.data.folderPath" style="position: absolute;opacity: 0; width:1px;" class=" flex-1" required>
                    </div>
                  </template>
                  <v-card class=" elevation-0">
                    <!-- <v-treeview activatable dense :items="trees" item-text="folderName" item-children="children" :active="folderActive" @update:active='chooseFolderTree'>
                        <template v-slot:label="{item}">
                          <span class=" cursor_pointer" @click="otherDialog.data.folderPath=item.folderName,otherDialog.data.folderId=item.id">
                            {{item.folderName}}
                          </span>
                        </template>
                      </v-treeview> -->
                    <v-treeview
                      v-model="selection"
                      class
                      activatable
                      :items="trees"
                      item-children="children"
                      transition
                      multiple
                      selectable
                    >
                      <template v-slot:label="{ item }">
                        <div
                          class="flex-y-center"
                          style="min-height: 38px"
                        >
                          <div
                            class="max-width max-height flex-y-center user-select"
                            style="min-height: 38px"
                            :title="item.folderName"
                          >
                            <!-- information.data.statusFlag=='1' -->
                            <a
                              class="grey--text text--darken-3 pl-1 max-width max-height flex-y-center"
                              :class="(folderActive.length&&(folderActive[0]===item.id))?'light-blue lighten-5':''"
                              style="min-height: 38px"
                              :style="{color:(item.statusFlag=='1')?'#ccc !important':'',cursor:(item.statusFlag=='1')?'text':'pointer'}"
                            >{{ item.folderName }}

                            </a>
                          </div>
                        </div>
                      </template>
                    </v-treeview>
                  </v-card>
                </v-menu>
              </div>
              <v-divider />
              <v-card-actions>
                <div class="max-width flex-y-center flex-justify-between">
                  <div class="flex-1 flex-justify-end">
                    <div v-if="document.isRemind" style=" position:relative; margin-right: 20px">
                      <v-btn width="100" height="30" color="error" depressed @click="delEventFileRemind">删除文档提醒</v-btn>
                    </div>
                    <v-btn width="80" height="30" class="mr-3" depressed @click="drawer.show = false">取消</v-btn>
                    <div style=" position:relative;">
                      <v-btn height="30" :loading="btnLoading" color="primary" width="80" depressed @click.stop="submitDocumentRemind">{{ document.isRemind? '确认修改' : '提交' }}</v-btn>
                    </div>
                  </div>
                </div>
              </v-card-actions>
            </div>
          </template>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- 呵呵 end -->
    <tongbu ref="tongbu" :tab="tab" />
  </v-layout>
</template>

<script>
import tongbu from './progress/tongbu.vue'
export default {
  components: { tongbu },
  data() {
    return {
      selection: [],
      documentReminderName: '',
      documentReminderRulName: '',
      documentReminderList: [],
      document: {
        data: {
          value: '',
          stageCategory: '',
          folderPath: ''
        },
        isRemind: false,
        id: '',
        pageNo: 1,
        pageSize: 999
      },
      tab: '0',
      search: {
        pageSize: 10,
        pageNo: 1,
        data: {}
      },
      total: 0,
      chooseId: '', // 选中的阶段id
      headers: [ // 阶段表头
        { text: '序号', value: 'rowIndex', width: '50', sortable: false },
        { text: '选择', value: 'radio', sortable: false },
        { text: '阶段名称', value: 'stageName' },
        { text: '是否允许前台创建事件', value: 'createFlag' },
        { text: '排序', value: 'sort' },
        { text: '状态', value: 'statusFlag' },
        { text: '操作', value: 'active', sortable: false }
      ],
      list: [], // 阶段数据
      btnLoading: false,
      listLoading: false,
      event: { // 事件列表
        search: {
          pageSize: 999,
          pageNo: 1,
          data: {}
        },
        headers: [
          { text: '序号', value: 'rowIndex', width: '50', sortable: false },
          { text: '事件名', value: 'eventName' },
          { text: '是否允许前台创建子任务', value: 'createFlag' },
          { text: '排序', value: 'sort' },
          { text: '状态', value: 'statusFlag' },
          { text: '操作', value: 'active', sortable: false }
        ],
        list: [],
        listLoading: false
      },
      dialog: { // 添加 编辑 阶段弹框
        show: false,
        type: '',
        data: {}
      },
      eventDialog: { // 事件 添加 编辑 弹框
        show: false,
        data: {}
      },

      progressEventTagList: [], // 节点标签列表
      ProjectCatalogueList: [], // 文件夹目录名称下拉
      roleList: [],
      taskPathId: [],
      isEditTaskPath: false,
      drawer: {
        show: false,
        data: {},
        task: { // 事件任务
          headers: [
            { text: '序号', value: 'rowIndex', width: '50', sortable: false },
            { text: '任务名称', value: 'taskName', show: true },
            { text: '路径', value: 'uploadPath', show: true },
            // { text: "起止日期", value: "project_name", show: true },
            { text: '操作', value: 'active', sortable: false }
          ],
          list: []
        },
        remind: { // 事件提醒
          headers: [
            { text: '序号', value: 'rowIndex', width: '50', sortable: false },
            { text: '任务状态', value: 'remindStatus', show: true },
            { text: '提醒语句', value: 'remindTip', show: true },
            { text: '提醒角色', value: 'roleName', show: true },
            { text: '操作', value: 'active', sortable: false }
          ],
          list: []
        },
        front: {
          headers: [
            { text: '序号', value: 'rowIndex', width: '50', sortable: false },
            { text: '阶段事件名称', value: 'eventName' },
            { text: '状态', value: 'taskStatus' },
            { text: '操作', value: 'active', sortable: false }
          ],
          list: []
        },
        tag: {
          headers: [
            { text: '序号', value: 'rowIndex', width: '50', sortable: false },
            { text: '阶段名称', value: 'name' },
            { text: '状态', value: 'birthday' },
            { text: '操作', value: 'active', sortable: false }
          ],
          list: []
        },
        upload: {
          headers: [
            { text: '序号', value: 'rowIndex', width: '50', sortable: false },
            { text: '路径', value: 'folderPath' },
            { text: '归档通过文件个数', value: 'numberRange' },
            { text: '归档后事件自动完成', value: 'autoDone' },
            { text: '操作', value: 'active', sortable: false }
          ],
          list: []
        }
      },
      stageAndEvent: [],
      taskStatus: {
        '0': { text: '未开始', value: '0' },
        '1': { text: '进行中', value: '1' },
        '2': { text: '已完成', value: '2' }
        // {text:'未开始',value:'0'},
        // {text:'进行中',value:'1'},
        // {text:'已完成',value:'2'},
        // {text:'未开始',value:'1'},
        // {text:'进行中',value:'2'},
        // {text:'已完成',value:'4'},
        // {text:'未开始 + 进行中',value:'3'},
        // {text:'已完成',value:'4'},
        // {text:'未开始 + 已完成',value:'5'},
        // {text:'进行中 + 已完成',value:'6'},
        // {text:'未开始 + 进行中 + 已完成',value:'7'},
      },

      otherDialog: { // 事件里其他表格 编辑添加 弹窗
        show: false,
        type: '',
        remindRole: [],
        preEventId: [],
        taskStatus: [],
        data: {}
      },
      progressCategory: '',
      setDialog: { // 事件 添加 编辑 弹框
        show: false,
        createFlag: ''
      },
      trees: [],
      folderActive: []
    }
  },
  computed: {
    // roleList
    roleName() { // 前置条件 阶段范湖名称
      const text = ''

      return text
    },
    selectName() { // 前置条件 阶段范湖名称
      let text = ''
      // if (this.otherDialog.data.preEventId&&this.otherDialog.data.preEventId.length) {
      //   let _h=(arr)=>{
      //     arr.forEach(item=>{
      //       if(item.eventName !== undefined){
      //         if(this.otherDialog.data.preEventId.includes(item.id)){
      //           text += item.eventName + '，'
      //         }
      //       }
      //       if(item.events&&item.events.length) _h(item.events)
      //     })
      //   }
      //   _h(this.stageAndEvent)
      //   if (text) text = text.substring(0, text.length - 1)
      // }
      // otherDialog.taskStatus
      if (this.otherDialog.data.preEventId) {
        const _h = (arr) => {
          arr.forEach(item => {
            // if (item.eventName !== undefined) {
            if (item.eventName) {
              if (this.otherDialog.data.preEventId === item.id) {
                text = item.eventName
              }
            }
            if (item.events && item.events.length) _h(item.events)
          })
        }
        _h(this.stageAndEvent)
      }
      return text
    },
    startAutoEventName() { // 新增 编辑 事件
      let text = ''
      if (this.eventDialog.data.startAutoEventId) {
        const _h = (arr, stageName) => {
          arr.forEach(item => {
            // if (item.eventName !== undefined) {
            if (item.eventName) {
              if (this.eventDialog.data.startAutoEventId === item.id) {
                // stageName
                text = (stageName ? (stageName + '/') : '') + item.eventName
              }
            }
            if (item.events && item.events.length) _h(item.events, item.stageName)
          })
        }
        _h(this.stageAndEvent, '')
      }
      return text
    },
    endAutoEventName() { // 新增 编辑 事件
      let text = ''
      if (this.eventDialog.data.endAutoEventId) {
        const _h = (arr, stageName) => {
          arr.forEach(item => {
            // if (item.eventName !== undefined) {
            if (item.eventName) {
              if (this.eventDialog.data.endAutoEventId === item.id) {
                text = (stageName ? (stageName + '/') : '') + item.eventName
              }
            }
            if (item.events && item.events.length) _h(item.events, item.stageName)
          })
        }
        _h(this.stageAndEvent, '')
      }
      return text
    },
    selectedNodes() {
      return this.selection
    }
  },
  watch: {
    taskPathId: {
      handler(val, old) {
        if (this.isEditTaskPath) {
          this.isEditTaskPath = false
          if (val && val.length) {
            let watchId = null
            let add = false
            if (val.length > old.length) {
              add = true
              watchId = val.find((t) => !old.includes(t))
            } else {
              watchId = old.find((t) => !val.includes(t))
            }
            const trees = this.$deepClone(this.trees)
            const pathId = []
            const pathName = []
            const getName = (arr, parentId) => {
              arr.forEach((_t, _i) => {
                if (!_t.isFild) {
                  if (parseInt(_t.id) === parseInt(parentId)) {
                    _t.isFild = true
                    if (pathId.indexOf(_t.id) === -1) {
                      pathId.push(_t.id)
                      pathName.unshift(_t.folderName)
                    }
                    if (_t.parentId !== 0) {
                      getName(trees, _t.parentId)
                    }
                  } else {
                    if (_t.children && _t.children.length) {
                      getName(_t.children, parentId)
                    }
                  }
                }
              })
            }
            getName(trees, watchId)
            if (add) {
              this.otherDialog.data.folderPath = this.otherDialog.data.folderPath ? (this.otherDialog.data.folderPath + ',' + pathName.join('/')) : pathName.join('/')
            } else {
              let pathArr = []
              if (this.otherDialog.data.folderPath) {
                pathArr = this.otherDialog.data.folderPath.split(',')
              }
              pathArr.splice(pathArr.indexOf(pathName.join('/')), 1)
              this.otherDialog.data.folderPath = pathArr.join(',')
              this.$forceUpdate()
            }
          } else {
            this.otherDialog.data.folderPath = ''
          }
          this.$forceUpdate()
        }
      },
      deep: true
    },
    tab: {
      handler(val, old) {
        this.getDocumentList()
      }
    },
    selectedNodes(newVal) {
      // this.otherDialog.data.folderPath = '已选（路径id）：' + this.selection.join(',')
      this.otherDialog.data.folderPath = this.collectNamesByIds(this.trees, newVal)
    }
  },
  // selectName" v-model="otherDialog.data.preEventId
  mounted() {
    this.getProgressCategory()
    this.getProgressStageList()
    this.getProgressEventTagList()
    this.roleQueryall()
    // this.getStageAndEvent()
    // this.getTree()
    this.getProjectCatalogueList()
    this.getDocumentList()
  },
  methods: {
    // 获取文件目录列表数据-文档提醒
    getDocumentList() {
      const param = new URLSearchParams()
      param.append('catalogueType', this.tab)
      param.append('pageNo', this.document.pageNo)
      param.append('pageSize', this.document.pageSize)
      this.$http
        .get(this.$api.projectCatalogueGetProjectCatalogue, { data: param })
        .then(res => {
          if (res.result.records && res.result.records.length) {
            this.documentReminderList = res.result.records.map(item => {
              return {
                text: item.catalogueName,
                value: item.id
              }
            })
          }
        })
    },
    submitDocumentRemind() {
      // addEventFileRemind
      const data = {
        // folderPath: this.otherDialog.data.folderPath,
        eventId: this.drawer.id,
        folderIds: this.selection.join(','),
        stageCategory: this.tab,
        catalogueId: this.eventDialog.data.catalogueId
      }
      if (this.document.isRemind) {
        data.id = this.document.id
        this.$http.put(this.$api.editEventFileRemind, { data: { ...data }}).then(res => {
          if (res.success) {
            this.$message.success(res.message)
            this.drawer.show = false
          } else {
            this.$message.error(res.message)
          }
        })
      } else {
        this.$http.post(this.$api.addEventFileRemind, { data: { ...data }}).then(res => {
          if (res.success) {
            this.$message.success(res.message)
            this.drawer.show = false
          } else {
            this.$message.error(res.message)
          }
        })
      }
    },
    getProgressCategory() {
      this.$http.get(this.$api.progressStageGetProgressCategory, { data: { categoryId: this.tab }}).then(res => {
        this.progressCategory = '1'
        if (res.result) {
          this.progressCategory = res.result.createFlag
        }
      })
    },
    initStageList() {
      this.total = 0
      this.search.pageNo = 1
      this.getProgressStageList()
    },
    // 改变每页的条数
    changePageSize(e) {
      this.search.pageSize = e
      this.search.pageNo = 1
      this.$forceUpdate()
      this.getProgressStageList()
    },
    // 获取文件夹目录名称下拉
    getProjectCatalogueList() {
      this.$http.get(this.$api.projectCatalogueGetProjectCatalogueList, {}).then(res => {
        if (res.result && res.result.length) {
          this.ProjectCatalogueList = res.result.map(item => {
            return {
              text: item.catalogueName,
              value: item.id
            }
          })
        }
      })
    },
    handlerSelect(e) {
      const param = new URLSearchParams()
      param.append('catalogueId', e.val)
      this.$http.get(this.$api.folderTemplateGetFolderTreeByCatalogueId, { data: param }).then(res => {
        this.trees = res.result || []
        this.otherDialog.data.folderPath = ''
      })
    },
    // 文件夹-获取目录树
    // getTree() {
    //   const param = new URLSearchParams()
    //   param.append('folderCategory', this.tab === '0' ? '1' : '2')
    //   this.$http
    //     .get(this.$api.folderTemplateGetFolderTree, { data: param })
    //     .then(res => {
    //       this.trees = res.result || []
    //       // item.statusFlag=='1'
    //       // if (type == "edit") {
    //       //   // this.chooseTree(this.active)
    //       // } else {
    //       //   if (this.trees && this.trees.length) {
    //       //     // this.active = [this.trees[0].id];
    //       //     this.treeActive = [this.trees[0].id]
    //       //     // this.chooseTree(this.active);
    //       //     this.updateActive(this.trees[0])
    //       //   } else {
    //       //     this.active = [];
    //       //     this.treeActive = [];
    //       //     this.information.data = {}; //选中节点的内容
    //       //     this.old_information = {};
    //       //     this.appliedToSubfolders = "";
    //       //     this.permissionsObj = {};
    //       //     this.rightTab = "1";
    //       //     this.search.pageNo = 1;
    //       //   }
    //       // }
    //     })
    // },
    // 获取进度 阶段
    getProgressStageList() {
      this.listLoading = true
      const search = { ...this.search.data }
      search.pageSize = this.search.pageSize
      search.pageNo = this.search.pageNo
      search.stageCategory = this.tab
      this.$http.get(this.$api.progressStageList, { data: { ...search }}).then(res => {
        this.listLoading = false
        this.list = res.result && res.result.records || []
        this.total = (res.result && res.result.total) || 0
      })
    },
    // 获取事件列表
    getProgressEventList() {
      this.event.listLoading = true
      const search = {}
      // search.pageSize=this.event.search.pageSize
      // search.pageNo=this.event.search.pageNo
      search.stageCategory = this.tab
      search.stageId = this.chooseId
      this.$http.get(this.$api.progressEventList, { data: search }).then(res => {
        this.event.list = res.result || []
        this.event.listLoading = false
      })
    },

    // 进度阶段-获取所有阶段与事件
    getStageAndEvent() {
      this.$http.get(this.$api.getStageAndEvent, { data: { stageCategory: this.tab }}).then(res => {
        // if (res.result && res.result.length) {
        //   res.result.forEach(item => {
        //     item.text = item.stageName
        //     item.value = item.id
        //   })
        // }
        this.stageAndEvent = res.result || []
      })
    },

    // 获取 事件 任务列表
    progressEventTaskList() {
      this.$http.get(this.$api.progressEventTaskList, { data: { eventId: this.drawer.id }}).then(res => {
        this.drawer.task.list = res.result || []
      })
    },
    // 获取 事件 提醒列表
    progressEventTaskEventRemindList() {
      this.$http.get(this.$api.progressEventTaskEventRemindList, { data: { eventId: this.drawer.id }}).then(res => {
        this.drawer.remind.list = res.result || []
      })
    },
    // 获取 事件 标签节点列表
    eventTagList() {
      this.$http.get(this.$api.eventTagList, { data: { eventId: this.drawer.id }}).then(res => {
        this.drawer.tag.list = res.result || []
      })
    },
    // 获取事件前置条件列表
    eventPreconditionList() {
      this.$http.get(this.$api.eventPreconditionList, { data: { eventId: this.drawer.id }}).then(res => {
        this.drawer.front.list = res.result || []
        this.$forceUpdate()
      })
    },
    // 获取事件必传附件列表
    getEventUploadList() {
      this.$http.get(this.$api.eventUploadList, { data: { eventId: this.drawer.id }}).then(res => {
        let list = res.result || []
        if (list.length) {
          list = list.map(t => {
            return { ...t, fileNumberMax: t.fileNumberMax || '', fileNumber: t.fileNumber || '' }
          })
        }
        this.drawer.upload.list = list
        this.$forceUpdate()
      })
    },
    collectNamesByIds(tree, ids, result = []) {
      for (const node of tree) {
        if (ids.includes(node.id)) {
          result.push(node.folderName)
        }
        if (node.children && node.children.length > 0) {
          this.collectNamesByIds(node.children, ids, result)
        }
      }
      return result
    },
    // 获取文档提醒详情
    getEventFileRemindList() {
      this.$http.get(this.$api.eventFileRemindList, { data: { eventId: this.drawer.id }}).then(res => {
        if (res.result && res.result.length) {
          this.document.id = res.result[0].id // 保存详情数据id  删除用
          this.eventDialog.data.catalogueId = Number(res.result[0].catalogueId)
          const folderIdsArray = res.result[0].folderIds.split(',').map(id => parseInt(id, 10))
          this.selection = folderIdsArray
          // 获取树数据
          const param = new URLSearchParams()
          param.append('catalogueId', res.result[0].catalogueId)
          this.$http.get(this.$api.folderTemplateGetFolderTreeByCatalogueId, { data: param }).then(res => {
            this.trees = res.result || []
            this.otherDialog.data.folderPath = this.collectNamesByIds(this.trees, folderIdsArray)
          })
          this.document.isRemind = true
        } else {
          this.document.isRemind = false
        }
        this.$forceUpdate()
      })
    },
    // 删除文档提醒
    delEventFileRemind() {
      if (confirm('是否确认删除文档提醒？')) {
        const param = new URLSearchParams()
        param.append('id', this.document.id)
        this.$http.delete(this.$api.deleteEventFileRemind, { data: param }).then(res => {
          this.$message.success(res.message)
          this.drawer.show = false
        })
      }
    },
    // 事件 节点标签
    getProgressEventTagList() {
      const search = {}
      search.pageSize = 100
      search.pageNo = this.search.pageNo
      this.$http.get(this.$api.progressEventTagList, { data: { ...search }}).then(res => {
        if (res.result.records && res.result.records.length) {
          res.result.records.forEach(item => {
            item.text = item.tagName
            item.value = item.id
          })
        }
        this.progressEventTagList = res.result.records || []
      })
    },
    chooseFolderTree(e) {
      if (e && e.length) {
        const _this = this
        this.otherDialog.data.folderId = e[0]
        let _f = false
        const _h = (arr) => {
          arr.forEach(item => {
            if (!_f) {
              if (item.id === e[0]) {
                _f = true
                _this.otherDialog.data.folderPath = item.folderName
              } else {
                if (item.children && item.children.length) _h(item.children)
              }
            }
          })
        }
        _h(this.trees)
      } else {
        this.otherDialog.data.folderId = ''
        this.otherDialog.data.folderPath = ''
      }
      this.$forceUpdate()
    },
    // 必传附件 选择路径树
    updateActive(e) {
      this.folderActive = [e.id]
      this.otherDialog.data.folderId = e.id
      this.otherDialog.data.folderPath = e.folderName
      this.$forceUpdate()
    },

    // 获取角色
    roleQueryall() {
      this.$http.get(this.$api.roleList, { data: { roleCategory: '1', pageNo: 1, pageSize: 100 }}).then(res => {
        if (res.result && res.result.records && res.result.records.length) {
          res.result.records.forEach(item => {
            item.text = item.roleName
            item.value = item.id
          })
        }
        this.roleList = res.result.records || []
      })
    },

    // 选择阶段
    chooseIt(e) {
      this.getProgressEventList()
    },
    // 确认 删除阶段  禁用 恢复 阶段
    activeList(api, method, title, params, event) {
      this.$confirm({ text: '是否确认' + title + '？' }).then((vm) => {
        const param = new URLSearchParams()
        for (const i in params) {
          param.append(i, params[i] || '')
        }
        vm.loading = true
        this.$http[method](api, { data: param }).then(res => {
          vm.show = false
          vm.loading = false
          if (event === 'event') {
            this.$message.success(res.message)
            this.getProgressEventList()
          } else {
            this.$message.success(res.message)
            if (method === 'delete') {
              this.initStageList()
            } else {
              this.getProgressStageList()
            }
          }
        }).catch(() => {
          vm.show = false
          vm.loading = false
        })
      }).catch(() => {})
    },
    // 禁用 恢复 阶段
    // progressStageSetStageStatus(id,type){
    //   if(confirm("是否确认"+(type==1?'禁用':'恢复')+"？")){
    //     let param = new URLSearchParams()
    //     param.append('id', id)
    //     param.append('statusFlag', type)
    //     this.$http.post(this.$api.progressStageSetStageStatus, {data:param}).then(res => {
    //         this.$message.success(res.message)
    //         this.getProgressStageList()
    //     });
    //   }
    // },

    // 删除阶段
    // showDel(id){
    //   if(confirm("是否确认删除？")){
    //     let param = new URLSearchParams()
    //     param.append('id', id)
    //     this.$http.delete(this.$api.progressStageDelete, {data:param}).then(res => {
    //         this.$message.success(res.message)
    //         this.initStageList()
    //         // this.getProgressStageList()
    //     });
    //   }
    // },
    // 点击提交
    subSave() {
      const u = navigator.userAgent
      const input = document.createElement('input')
      document.body.appendChild(input)
      input.focus()
      this.$nextTick(() => {
        document.body.removeChild(input) // 获取焦点移除节点
        if (u.indexOf('Firefox') > -1) {
          setTimeout(() => {
            this.$refs.form.click()
          }, 300)
        } else {
          this.$refs.form.click()
        }
      })
    },
    // 阶段确认 添加 编辑
    submitAdd() {
      let url = this.$api.progressStageAdd
      let method = 'post'
      if (this.dialog.data.id) {
        url = this.$api.progressStageEdit
        method = 'put'
      }
      this.btnLoading = true
      this.$http[method](url, { data: { ...this.dialog.data }}).then(res => {
        this.$message.success(res.message)
        this.dialog.show = false
        this.btnLoading = false
        this.dialog.data = {}
        this.getProgressStageList()
      }).catch(() => {
        this.btnLoading = false
      })
    },
    progressEventSetStageStatus(id, type) {
      if (confirm('是否确认' + (type === '1' ? '禁用' : '恢复') + '？')) {
        const param = new URLSearchParams()
        param.append('id', id)
        param.append('statusFlag', type)
        this.$http.post(this.$api.progressEventSetStageStatus, { data: param }).then(res => {
          this.$message.success(res.message)
          this.getProgressEventList()
        })
      }
    },
    // 删除事件
    showEventDel(id) {
      if (confirm('是否确认删除？')) {
        const param = new URLSearchParams()
        param.append('id', id)
        this.$http.delete(this.$api.progressEventDelete, { data: param }).then(res => {
          this.$message.success(res.message)
          this.getProgressEventList()
        })
      }
    },
    // 事件 编辑弹窗
    showEdit(item) {
      this.eventDialog.show = true
      this.getStageAndEvent()
      this.eventDialog.data = { ...item }
    },
    // 确认 添加编辑事件
    eventSubmitAdd() {
      let url = this.$api.progressEventAdd
      let method = 'post'
      if (this.eventDialog.data.id) {
        url = this.$api.progressEventEdit
        method = 'put'
      }
      const _data = { ...this.eventDialog.data }
      _data.stageCategory = this.tab
      _data.stageId = this.chooseId
      this.btnLoading = true
      this.$http[method](url, { data: { ..._data }}).then(res => {
        this.$message.success(res.message)
        this.eventDialog.show = false
        this.eventDialog.data = {}
        this.btnLoading = false
        this.getProgressEventList()
      }).catch(() => {
        this.btnLoading = false
      })
    },

    // 配置事件 弹窗
    showDrawer() {
      // this.drawer.data = { ...item };
      if (Number(this.tab) === 0) {
        this.documentReminderName = '项目文档目录'
        this.documentReminderRulName = '项目文档路径'
      }
      if (Number(this.tab) === 1) {
        this.documentReminderName = '中心文档目录'
        this.documentReminderRulName = '中心文档路径'
      }
      this.eventDialog.data.catalogueId = ''
      this.otherDialog.data.folderPath = ''
      this.trees = []
      this.selection = []
      this.drawer.show = true
    },

    // 事件里的弹窗 确认删除
    otherDel(id, url, cb) {
      // if(confirm("是否确认删除？")){
      //   this.$http.delete(url, {data:{ id: id }}).then(res => {
      //     this.$message.success(res.message)
      //     cb&&cb()
      //   });
      // }

      this.$confirm({ text: '是否确认删除？' }).then((vm) => {
        vm.loading = true
        this.$http.delete(url, { data: { id: id }}).then(res => {
          vm.show = false
          vm.loading = false
          this.$message.success(res.message)
          cb && cb()
        }).catch(() => {
          vm.show = false
          vm.loading = false
        })
      }).catch(() => {})
    },

    // 前置条件 阶段名称 选择
    changepreEvent(e, id, key) {
      if (e) {
        // this.otherDialog.data.preEventId = id
        if (this.otherDialog.show) this.otherDialog.data.preEventId = id
        if (this.eventDialog.show && key) this.$set(this.eventDialog.data, key, id)
      } else {
        // this.otherDialog.data.preEventId = ''
        if (this.otherDialog.show) this.otherDialog.data.preEventId = ''
        if (this.eventDialog.show && key) this.$set(this.eventDialog.data, key, '')
      }
      this.$forceUpdate()
      // this.otherDialog.data.preEventId=item.id,$forceUpdate()
    },
    // 前置条件 编辑
    frontEdit(e) {
      // this.taskStatus
      if (e.taskStatus) {
        // switch(e.taskStatus){
        //   case '1':
        //     this.otherDialog.taskStatus=['1']
        //     break;
        //   case '2':
        //     this.otherDialog.taskStatus=['2']
        //     break;
        //   case '3':
        //     this.otherDialog.taskStatus=['1','2']
        //     break;
        //   case '4':
        //     this.otherDialog.taskStatus=['4']
        //     break;
        //   case '5':
        //     this.otherDialog.taskStatus=['1','4']
        //     break;
        //   case '6':
        //     this.otherDialog.taskStatus=['2','4']
        //     break;
        //   case '7':
        //     this.otherDialog.taskStatus=['1','2','4']
        //     break;
        // }
        this.otherDialog.taskStatus = e.taskStatus.split(',')
      } else {
        this.otherDialog.taskStatus = []
      }
      this.otherDialog.data = e
      this.otherDialog.show = true
      // otherDialog.taskStatus -->
      //             <a  @click="frontEdit(...item)">编辑</a>
    },
    // 必传附件 编辑
    uploadEdit() {

    },
    subOtherSave() {
      const u = navigator.userAgent
      const input = document.createElement('input')
      document.body.appendChild(input)
      input.focus()
      this.$nextTick(() => {
        document.body.removeChild(input) // 获取焦点移除节点
        if (u.indexOf('Firefox') > -1) {
          setTimeout(() => {
            this.$refs.otherForm.click()
          }, 300)
        } else {
          this.$refs.otherForm.click()
        }
      })
    },
    // 确认添加 编辑 事件里的其他操作
    otherSubmitAdd() {
      let url = null
      let method = 'post'
      let _data = { ...this.otherDialog.data }
      _data.stageCategory = this.tab
      _data.stageId = this.chooseId
      _data.eventId = this.drawer.id
      if (this.otherDialog.data.id) {
        method = 'put'
        if (this.drawer.type === 1) {
          _data.uploadFolderId = this.taskPathId.join(',')
          url = this.$api.progressEventTaskEdit
        } else if (this.drawer.type === 2) {
          _data.remindRole = this.otherDialog.remindRole.join(',')
          url = this.$api.progressEventTaskEventRemindEdit
        } else if (this.drawer.type === 3) {
          _data = { ...this.otherDialog.data }
          _data.eventId = this.drawer.id
          url = this.$api.saveEventTags
          JSON.stringify(_data)
        } else if (this.drawer.type === 4) {
          url = this.$api.editEventPrecondition
          // _data.preEventId=this.otherDialog.data.preEventId.join(',')||'' otherDialog.taskStatus

          _data.taskStatus = ''
          if (this.otherDialog.taskStatus && this.otherDialog.taskStatus.length) {
            _data.taskStatus = this.otherDialog.taskStatus.join(',')
            // let sum = this.otherDialog.taskStatus.reduce(function(a, b){
            //   return parseInt(a)+parseInt(b);
            // }, 0);
            // _data.taskStatus=sum.toString()
          }
          JSON.stringify(_data)
        } else if (this.drawer.type === 5) {
          url = this.$api.editEventUpload
        }
      } else {
        if (this.drawer.type === 1) {
          _data.uploadFolderId = this.taskPathId.join(',')
          url = this.$api.progressEventTaskAdd
        } else if (this.drawer.type === 2) {
          _data.remindRole = this.otherDialog.remindRole.join(',')
          url = this.$api.progressEventTaskEventRemindAdd
        } else if (this.drawer.type === 3) {
          _data = { ...this.otherDialog.data }
          _data.eventId = this.drawer.id
          JSON.stringify(_data)

          url = this.$api.saveEventTags
        } else if (this.drawer.type === 4) {
          // _data={...this.otherDialog.data}
          url = this.$api.addEventPrecondition
          // _data.preEventId=this.otherDialog.data.preEventId.join(',')||''
          _data.taskStatus = ''
          if (this.otherDialog.taskStatus && this.otherDialog.taskStatus.length) {
            _data.taskStatus = this.otherDialog.taskStatus.join(',')
            // let sum = this.otherDialog.taskStatus.reduce(function(a, b){
            //   return parseInt(a)+parseInt(b);
            // }, 0);
            // _data.taskStatus=sum.toString()
          }
          JSON.stringify(_data)
        } else if (this.drawer.type === 5) {
          url = this.$api.addEventUpload
        }
      }

      this.btnLoading = true
      this.$http[method](url, { data: { ..._data }}).then(res => {
        this.$message.success(res.message)
        this.otherDialog.show = false
        this.btnLoading = false
        this.otherDialog.data = {}
        if (this.drawer.type === 1) {
          this.progressEventTaskList()
        } else if (this.drawer.type === 2) {
          this.progressEventTaskEventRemindList()
        } else if (this.drawer.type === 3) {
          this.eventTagList()
        } else if (this.drawer.type === 4) {
          this.eventPreconditionList()
        } else if (this.drawer.type === 5) {
          this.getEventUploadList()
        }
      }).catch(() => {
        this.btnLoading = false
      })
    },
    // 设置是否允许前台创建阶段
    setFlag() {
      this.btnLoading = true
      const param = new URLSearchParams()
      param.append('categoryId', this.tab)
      param.append('createFlag', this.setDialog.createFlag)
      this.$http.post(this.$api.progressStageSetProgressCreateFlag, { data: param }).then(res => {
        this.setDialog.show = false
        this.btnLoading = false
        this.setDialog.createFlag = ''
        this.$message.success(res.message)
        this.getProgressCategory()
      }).catch(() => {
        this.btnLoading = false
      })
    },
    showTongbu() {
      this.$refs.tongbu && this.$refs.tongbu.show()
    },
    // 任务编辑弹窗
    editTask(e) {
      const item = { ...e }
      this.isEditTaskPath = false
      this.otherDialog.data = item
      this.taskPathId = item.uploadFolderId ? item.uploadFolderId.split(',') : []
      this.$set(this.otherDialog.data, 'folderPath', item.uploadPath || '')
      this.$forceUpdate()
      this.otherDialog.show = true
    },
    // 任务事件选择路径
    updateTaskActive(e) {
      this.isEditTaskPath = true
      this.$forceUpdate()
    },
    // 前置条件列表 状态返回文本
    taskStatusBack(e) {
      const _text = []
      e.split(',').forEach(item => {
        if (this.taskStatus[item]) {
          _text.push(this.taskStatus[item].text)
        }
      })
      return _text.join(',')
    },
    inputPlanStartTimeAuto(e) {
      if (e !== '1') {
        this.eventDialog.data.startAutoEventId = ''
        this.eventDialog.data.startAutoType = ''
        this.eventDialog.data.startAutoDayType = ''
        this.eventDialog.data.startAutoDays = ''
      }
    },
    inputPlanEndTimeAuto(e) {
      if (e !== '1') {
        this.eventDialog.data.endAutoEventId = ''
        this.eventDialog.data.endAutoType = ''
        this.eventDialog.data.endAutoDayType = ''
        this.eventDialog.data.endAutoDays = ''
      }
    }

    // 确认 添加编辑事件
    // submit() {
    //     let url=this.$api.progressEventAdd
    //     let method='post'
    //     if(this.drawer.data.id){
    //         url=this.$api.progressEventEdit
    //         method='put'
    //     }
    //     // this.drawer.data

    // }
  }
}
</script>

<style lang="scss" scoped>
.form-title {
  width: 150px;
  color: #777;
}
</style>
